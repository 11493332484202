import {
  ApolloLink,
  Operation,
  FetchResult,
  Observable,
} from '@apollo/client/core';
import { print, GraphQLError } from 'graphql';
import { createClient, ClientOptions, Client } from 'graphql-sse';

class SSELink extends ApolloLink {
  private client: Client;

  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          // next: sink.next.bind(sink),
          next: (value) => {
            // ExecutionResult에서 FetchResult로 변환
            const result: FetchResult = {
              data: value.data,  // data만 가져오기
              errors: value.errors,  // 오류가 있다면 errors 필드 추가
              // extensions: value.extensions,  // 확장 정보
            };
            sink.next(result);  // 변환된 FetchResult를 전달
          },
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
        },
      );
    });
  }
}

export const sseLink = new SSELink({
  url: `${process.env.REACT_APP_API_HOST}/graphql/stream`,
  headers: () => {
    const accessToken = localStorage.getItem('access_token');
    console.log('SSELink URL:', `${process.env.REACT_APP_API_HOST}/graphql/stream`);
    console.log('Access Token:', accessToken);
    return {
      Authorization: `Bearer ${accessToken}`,
    };
  },
  credentials: 'include'
});