import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import {
  CreateNotificationMutationVariables,
  useCreateNotificationMutation,
} from "../../generated/graphql";
import { useNavigate } from "react-router-dom";

function RealSendForm(): React.ReactElement {
  const errorToast = useToast({
    position: 'top-right',
    isClosable: true,
    status: 'error',
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<CreateNotificationMutationVariables>();

  const navigate = useNavigate();
  const [createNotification, { loading }] = useCreateNotificationMutation();

  const onSubmit = async (formData: CreateNotificationMutationVariables) => {
    // userId를 숫자로 변환
    const processedData = {
      sendInput: {
        ...formData.sendInput,
        userId: Number(formData.sendInput.userId), // userId를 명시적으로 숫자로 변환
      },
    };

    // createNotification 뮤테이션 함수 실행
    const { data } = await createNotification({ variables: processedData });
    // if (data?.createNotification.errors) {
    //   data.createNotification.errors.forEach((err) => {
    //     const field = `sendInput.${err.field}` as
    //       | "sendInput.userId"
    //       | "sendInput.text";
    //     setError((field + err.field) as Parameters<typeof setError>[0], {
    //       message: err.message,
    //     });
    //   });
    // }
    // if (data && data?.createNotification.accessToken) {
    //   localStorage.setItem("access_token", data.login.accessToken);
    //   navigate("/");
    // }
    if (data && data?.createNotification) {
      // navigate("/");
      console.log('data?.createNotification: ', data?.createNotification)
    } else {
      // errorToast({
      //   title: `알림 중 에러 발생`,
      //   description: `${data?.createNotification.errors}`
      // });
    }
  };
  return (
    <Box
      rounded="lg"
      bg={useColorModeValue("white", "gray.700")}
      boxShadow="lg"
      p={8}
    >
      <Stack as="form" spacing={4} onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={!!errors.sendInput?.userId}>
          <FormLabel>알림을 받을 유저의 번호</FormLabel>
          <Input
            type="userId"
            placeholder="번호를 입력하세요."
            {...register("sendInput.userId", {
              required: "이메일 또는 아이디를 입력해주세요.",
            })}
          />
          <FormErrorMessage>
            {errors.sendInput?.userId &&
              errors.sendInput.userId.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.sendInput?.text}>
          <FormLabel>알림 메세지</FormLabel>
          <Input
            type="text"
            placeholder="알림 메세지를 입력하세요."
            {...register("sendInput.text", {
              required: "암호를 입력해주세요.",
            })}
          />
          <FormErrorMessage>
            {errors.sendInput?.text && errors.sendInput.text.message}
          </FormErrorMessage>
        </FormControl>

        <Divider />

        <Button colorScheme="teal" type="submit">
          알림 전송
        </Button>
      </Stack>
    </Box>
  );
}

function SendForm(): React.ReactElement {
  return (
    <Stack spacing={8} mx="auto" maxW="lg" py={12} px={6}>
      <Stack align="center">
        <Heading fontSize="4xl">지브리 명장면 프로젝트</Heading>
        <Text fontSize="lg" color="gray.600">
          알림을 생성해 보세요!
        </Text>
      </Stack>

      <RealSendForm />
    </Stack>
  );
}

export default SendForm;
