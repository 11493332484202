import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import CommonLayout from "../components/CommonLayout";
import SendForm from "../components/send/SendForm";

function Send(): React.ReactElement {
  return (
    <Box bg={useColorModeValue("gray.50", "gray.800")}>
      <CommonLayout>
        <Flex align="center" justify="center">
          <SendForm />
        </Flex>
      </CommonLayout>
    </Box>
  );
}

export default Send;
